import { useNavigate } from "react-router-dom";

import '../css/style.css';
import logo from '../images/minda-logo.png';
import { useState, useEffect } from "react";
import { resetPwd, sendOtp } from "../api";

function ForgotPassword() {
    const navigate = useNavigate();

    const [userId, setUserId] = useState('');
    const [otp, setOtp] = useState('');
    const [newPwd, setNewPwd] = useState('');
    const [confirmPwd, setConfirmPwd] = useState('');


    useEffect(()=>{
        let token = sessionStorage.getItem('token');
        if (token) {
          navigate('../home', {replace: true});
        }
    },[]);
    
    const submitResetPassword = () => {
        if (!userId) {
            alert ('Enter Mobile as userId');
            return;
        }
        if (!otp) {
            alert ('OTP is required');
            return;
        }
        if (!newPwd) {
            alert ('New Password is required');
            return;
        }
        if (!confirmPwd) {
            alert ('Confirm Password is required');
            return;
        }
        if (newPwd !== confirmPwd) {
            alert ('Password and Confirm Password should be same');
            return;
        }
        resetPwd(userId, otp, newPwd, confirmPwd).then(resp=> {
            if(resp && resp.success) {
                alert('Password reset successfully');
                navigate('/login', {replace: true});
            } else {
                alert('Failed to reset password');
            }
        })
    }

    const sendOtpCall = () => {
        if (!userId) {
            alert ('Enter Mobile as userId');
            return;
        }
        sendOtp(userId).then(resp=> {
            if(resp && resp.success) {
                alert('OTP sent successfully');
            } else {
                alert('Failed to send OTP');
            }
        })
    }

    return (
        <div class="main-page">
            <div class="login-main">

                <div class="logo">
                    <img src={logo} />
                </div>

                <div class="login-wrapper">

                    <div class="login-form">
                        <diV class="title text-center">
                            <h1>Reset Your Password</h1>
                            <p class="text-slate-500 dark:text-zink-200">Enter your details to reset your password.</p>
                        </diV>
                        <div class="form">
                            <div class="form-group mobile">
                                <input type="text" placeholder="Enter Mobile" tabindex="0" onChange={(e)=>setUserId(e.target.value)}/>
                                <button type="button" class="btn btn-secondary" onClick={sendOtpCall}>Send OTP</button>
                            </div>
                            <div class="form-group otp">
                                <input type="text" placeholder="Enter OTP" tabindex="1" onChange={(e)=>setOtp(e.target.value)}/>
                            </div>
                            <div class="form-group new-password">
                                <input type="password" placeholder="Enter New Password" tabindex="2" onChange={(e)=>setNewPwd(e.target.value)}/>
                            </div>
                            <div class="form-group confirm-password">
                                <input type="password" placeholder="Confirm New Password" tabindex="3" onChange={(e)=>setConfirmPwd(e.target.value)}/>
                            </div>
                            <div class="form-group mt-20">
                                <button type="submit" class="btn btn-primary btn-block" onClick={submitResetPassword} >Submit</button>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    );
}

export default ForgotPassword;
