import { useNavigate, useParams } from "react-router-dom";
import {get} from 'lodash' ;
import '../css/style.css';
import '../css/font-awesome-pro.css';
import GaugeChart from 'react-gauge-chart'
import { useEffect, useState } from "react";
import { getData, getSerialData } from "../api";

function DeviceDetails() {
    const navigate = useNavigate();
    const [data, setData] = useState({});
    const { serialNumber } = useParams();

    useEffect(()=>{
        let token = sessionStorage.getItem('token');
        if (!token) {
          navigate('/', {replace: true});
        }
        const admin = sessionStorage.getItem('isAdmin');
        if(admin && admin == 'true' && !serialNumber) {
            navigate('list', {replace: true});
        }
        if (serialNumber) {
            getSerialData(serialNumber).then((resp)=> {
                setData(resp);
            })
        } else {
            getData().then((resp)=> {
                setData(resp);
            })
        }
    }, [])

    function navigateDetails() {
        if (serialNumber) {
            return `#home/details2/${serialNumber}`
        }
        return "#home/details2/"
    }
    
    return (
        <div class="page-content">
            <section class="">
                <div class="grid-1">

                    <div class="item-2 col-xl-4 col-md-4 col-lg-4 col-xs-4 counter-matrix icon-effect icon-key card bg-orange-100">
                        <div class="card-body1">
                            <div class="card-body">
                                <p class="text">Total Key Count</p>
                                <div class="counter-value">{get(data, 'data.keycount', '-')} nos</div>
                                {/* <p class="text-2">Last Day Key Count <span class="highlight">23</span></p> */}
                            </div>
                        </div>
                    </div>
                    <div class="item-1 col-xl-4 col-md-4 col-lg-4 col-xs-4 counter-matrix card icon-effect icon-motor bg-green-100">
                        <div class="card-body1">
                            <div class="card-body">
                                <p class="text">Total Motor Run Time</p>
                                <div class="counter-value">{get(data, 'data.runtime', '-')} mins</div>
                                {/* <p class="text-2">Last Day Motor Run <span class="highlight">100 min</span></p> */}
                            </div>
                        </div>
                    </div>
                    <div class="item-4 col-xl-4 col-md-4 col-lg-4 col-xs-4 counter-matrix card icon-effect icon-energy bg-purple-100">
                        <div class="card-body1">
                            <div class="card-body">
                                <p class="text">Total Units Consumption</p>
                                <div class="counter-value">{parseInt(get(data, 'data.energy', 0))/1000} units</div>
                                {/* <p class="text-2">Last Day Unit <span class="highlight">7.86</span></p> */}
                            </div>
                        </div>
                    </div>
                    <div class="item-3 col-xl-3 col-md-3 col-lg-3 col-xs-3 counter-matrix card icon-effect icon-key bg-sky-100">
                        <div class="card-body1">

                            <div class="card-body">
                                <p class="text">Total Flat Cut</p>
                                <div class="counter-value">{get(data, 'data.flatcut', '-')} nos</div>
                            </div>
                        </div>
                    </div>
                    <div class="item-3 col-xl-3 col-md-3 col-lg-3 col-xs-3 counter-matrix card icon-effect icon-key bg-sky-100">
                        <div class="card-body1">

                            <div class="card-body">
                                <p class="text">Single - Flat</p>
                                <div class="counter-value">{get(data, 'data.singleside', '-')} nos</div>
                            </div>
                        </div>
                    </div>
                    <div class="item-3 col-xl-3 col-md-3 col-lg-3 col-xs-3 counter-matrix card icon-effect icon-key bg-sky-100">
                        <div class="card-body1">

                            <div class="card-body">
                                <p class="text">Double - Flat</p>
                                <div class="counter-value">{get(data, 'data.doubleside', '-')} nos</div>
                            </div>
                        </div>
                    </div>
                    <div class="item-3 col-xl-3 col-md-3 col-lg-3 col-xs-3 counter-matrix card icon-effect icon-key bg-sky-100">
                        <div class="card-body1">

                            <div class="card-body">
                                <p class="text">Total Bit Cut</p>
                                <div class="counter-value">{get(data, 'data.bitcut', '-')} nos</div>
                                {/* <p class="text-2">Last Day Count <span class="highlight">7</span></p> */}
                            </div>
                        </div>
                    </div>
                    <div class="item-4 col-lg-6 col-md-6 col-sm-6 col-xs-6 detail-matrix card bg-purple-100">
                        <div class="card-header">Flat Cutter Health</div>
                        <div class="card-body">
                            <GaugeChart
                                id="gauge-chart1"
                                colors={["#00e100", "#fefe00", "#ee0000"]}
                                nrOfLevels={5}
                                arcWidth={0.3}
                                percent={get(data, 'data.flatcut', 0) / get(data, 'flatCutterHealth', 1)>1?1:
                                    get(data, 'data.flatcut', 0) / get(data, 'flatCutterHealth', 1)}
                                hideText
                            />
                        </div>
                    </div>
                    <div class="item-4 col-lg-6 col-md-6 col-sm-6 col-xs-6 detail-matrix card bg-purple-100">
                        <div class="card-header">Bit Cutter Health</div>
                        <div class="card-body">
                            <GaugeChart
                                id="gauge-chart1"
                                colors={["#00e100", "#fefe00", "#ee0000"]}
                                nrOfLevels={5}
                                arcWidth={0.3}
                                percent={get(data, 'data.bitcut', 0) / get(data, 'bitCutterHealth', 1)>1?1:
                                    get(data, 'data.bitcut', 0) / get(data, 'bitCutterHealth', 1)}
                                hideText
                            />
                        </div>
                    </div>

                    <div class="item-4 col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center mt-20">
                        <a href={navigateDetails()} class="btn btn-primary">
                            View More Data</a>
                    </div>
                </div>

            </section>
        </div>
    );
}

export default DeviceDetails;
