import { useNavigate } from "react-router-dom";
import {get} from 'lodash' ;
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';

import '../css/style.css';
import '../css/font-awesome-pro.css';
import userIcon from '../images/user.jpg';
import prediction1Icon from '../images/temp-01.png';
import prediction2Icon from '../images/temp-02.png';
import prediction3Icon from '../images/temp-03.png';
import prediction4Icon from '../images/temp-04.png';
import prediction5Icon from '../images/temp-05.png';
import gaugeChart from "../images/gauge-chart-2.png";
import { useEffect, useState } from "react";
import { addDevice, getAllDevices, getData, updateDevice } from "../api";
import DataTable from 'datatables.net-dt';
 

const modaLStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: 500,
    bgcolor: 'background.paper',
    borderRadius: '3px',
    // border: '2px solid #000',
    // boxShadow: 24,
    p: 5,
    '& .MuiTextField-root': { m: 1, width: '25ch' },
  };

function DeviceList() {
    const navigate = useNavigate();
    const [devices, setDevices] = useState([]);
    const [deviceData, setDeviceData] = useState({});
    const [showDeviceModal, setDeviceModal] = useState(false);
    const [isDealer, setDealer] = useState(false);
    
    const [formErr, setFormErr] = useState({});

    useEffect(()=> {
        if (devices.length) {
            new DataTable('#myTable')
        }
    },[devices]);

    function onValueChange (e) {
        let key = e.target.id;
        let value = e.target.value;
        let data = {...deviceData};
        let err = {...formErr};
        if(!value && (key == 'machSerial' || key == 'deviceId')) {
            err[key] = 'Required';
            setFormErr(err);
        } else {
            err[key] = '';
            setFormErr(err);
        }
        data[key] = value;
        setDeviceData(data);
    }

    function isFormError () {
        for (let k of Object.keys(formErr)) {
            if (formErr[k]) {
                return true;
            }
        }
        return false;
    }

    function onSubmit() {
        if (isFormError()) {
            alert('Form has error!');
        }
        console.log(deviceData);
        if (deviceData._id) {
            updateDevice(deviceData).then((resp)=> {
                if (!resp.error) {
                    setDeviceModal(false);
                    getAllDevices().then((resp)=> {
                        setDevices(resp);
                    })
                }
            })
        } else {
            addDevice(deviceData).then((resp)=> {
                if (resp && !resp.error) {
                    setDeviceModal(false);
                    getAllDevices().then((resp)=> {
                        setDevices(resp);
                    })
                }
            })
        }
    }

    useEffect(()=>{
        let token = sessionStorage.getItem('token');
        let isAdmin = sessionStorage.getItem('isAdmin');
        let dealer = sessionStorage.getItem('isDealer');
        setDealer(dealer=='true');
        if (!token || !isAdmin || isAdmin != 'true') {
          navigate('/', {replace: true});
        }
        getAllDevices().then((resp)=> {
            setDevices(resp);
        })
    }, [])

    const machineHealth = (data)=> {
        let runtime = (get(data, 'data.runtime',0))/60;
        if (runtime < 100) {
            return (<><img src={prediction1Icon} width="7px" alt="" /> Excellent</>)
        }
        else if (runtime < 200) {
            return (<><img src={prediction2Icon} width="7px" alt="" /> Good</>)
        }
        else if (runtime < 300) {
            return (<><img src={prediction3Icon} width="7px" alt="" /> Fair</>)
        }
        else if (runtime < 400) {
            return (<><img src={prediction4Icon} width="7px" alt="" /> Poor</>)
        }
        else {
            return (<><img src={prediction4Icon} width="7px" alt="" /> Very Poor</>)
        }
    }

    const goToDeviceData = (device) => {
        navigate(`/home/device/${device.machSerial}`, {replace: true});
    }

    const openAddDevice = () => {
        setDeviceData({
            _id: '',
            machSerial: '',
            deviceId: '',
            motorHealth: 500,
            flatCutterHealth: 5000,
            bitCutterHealth: 70
        });
        setDeviceModal(true);
        setFormErr({
            machSerial: 'Required',
            deviceId: 'Required',
            motorHealth: '',
            flatCutterHealth: '',
            bitCutterHealth: '',
        })
    }
    
    const openEditDevice = (device) => {
        setDeviceData(device);
        setDeviceModal(true);
        setFormErr({
            machSerial: '',
            deviceId: '',
            motorHealth: '',
            flatCutterHealth: '',
            bitCutterHealth: '',
        })
    }
    
    return (
        <>
            <div class="page-content">
                <section class="grid-1">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 card">

                        <div class="card-header">
                            <h1 class="card-heading">All Devices</h1>
                            <div class="group-btn">
                                <Button onClick={() => openAddDevice()}>Add Device</Button>

                            </div>
                        </div>

                        <div class="card-body">
                            {/* <div class="my-filter">
                                <div class="group-btn">
                                    <div class="input-group">
                                        <label >Sort By</label>
                                        <select>
                                            <option value="7">Last 7 Days</option>
                                            <option value="30">Last 30 Days</option>
                                            <option value="90">Last 90 Days</option>
                                            <option value="180">Last 180 Days</option>
                                            <option value="365">Yearly</option></select>
                                    </div>
                                    <a class="btn btn-primary download-btn font-12">Download</a>
                                </div>

                                <div class="group-btn">
                                    <div class="search">
                                        <i class="liv-search w-18"></i>
                                        <input type="search" placeholder="Search" />
                                    </div>
                                </div>
                            </div> */}
                            <div class="my-table">
                                <table id="myTable" class="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col">Serial Number</th>
                                            <th scope="col">Device Id</th>
                                            <th scope="col">Owner</th>
                                            {!isDealer && <th scope="col">Dealer</th>}
                                            <th scope="col">Health</th>
                                            <th scope="col">Total Key Cut</th>
                                            <th scope="col">Status</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {devices.map(d => (
                                            <tr>
                                                <td><a onClick={() => goToDeviceData(d)}>{d.machSerial}</a></td>
                                                <td>{d.deviceId}</td>
                                                <td>{get(d, 'owner.fName')}</td>
                                                {!isDealer && <td>{get(d, 'dealer.name')}</td>}
                                                <td>{machineHealth(d)}</td>
                                                <td>{get(d, 'data.keycount')}</td>
                                                <td>{get(d, 'isActive', false) ?
                                                    <div class="indicator green" style={{ width: '7px', height: '7px' }}></div> :
                                                    <div class="indicator red" style={{ width: '7px', height: '7px' }}></div>
                                                }</td>
                                                <td><a onClick={() => openEditDevice(d)}><i class="fa-solid fa-pen-to-square"></i></a></td>
                                            </tr>
                                        ))}



                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>
                </section>
            </div>
            <Modal
                open={showDeviceModal}
                onClose={() => setDeviceModal(false)}
                aria-labelledby="Add Device"
                aria-describedby="add and update device"
            >
                <Box
                    component="form"
                    sx={modaLStyle}
                    autoComplete="off"
                >
                    <h2>{deviceData['_id']?"Edit":"Add"} Device
                        <span style={{float: 'right', cursor: 'pointer'}} onClick={() => setDeviceModal(false)}>✕</span>
                    </h2>
                    
                    <Grid container 
                        spacing={2} 
                        alignItems="center">
                        <Grid item xs={6}>
                            <TextField
                                onChange={onValueChange}
                                helperText={formErr['machSerial']}
                                error={!!formErr['machSerial']}
                                required
                                id="machSerial"
                                value={deviceData['machSerial']}
                                label="Serial Number"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                onChange={onValueChange}
                                helperText={formErr['deviceId']}
                                error={!!formErr['deviceId']}
                                required
                                id="deviceId"
                                value={deviceData['deviceId']}
                                label="Device Id"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                onChange={onValueChange}
                                id="motorHealth"
                                value={deviceData['motorHealth']}
                                label="Motor Health"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                onChange={onValueChange}
                                id="flatCutterHealth"
                                value={deviceData['flatCutterHealth']}
                                label="Flat Cutter Health"
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                onChange={onValueChange}
                                id="bitCutterHealth"
                                value={deviceData['bitCutterHealth']}
                                label="Bit Cutter Health"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button onClick={onSubmit} disabled={isFormError()}>Save</Button>
                        </Grid>
                    </Grid>
                </Box>
            </Modal>
        </>
    );
}

export default DeviceList;
